<!--  -->
<template>
  <div class="phone_bg">
    <header>
      <slot name="header"></slot>
    </header>
    <main>
      <slot></slot>
    </main>
    <footer>
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {}
  },
  methods: {},
  mounted() {},
  components: {}
}
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
.phone_bg {
  display: inline-block;
  width: 419px;
  height: 865px;
  padding: 110px 29px 113px;
  background: url('../../assets/images/wx/iphone_backImg.png') no-repeat;
  background-size: 100% auto;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/*滚动区域背景*/
::-webkit-scrollbar-track-piece {
  background-color: #fff4f4;
  -webkit-border-radius: 6px;
}
/*竖向滚动条*/
::-webkit-scrollbar-thumb:vertical {
  height: 5px;
  background-color: #ddd;
  -webkit-border-radius: 6px;
}
/*横向滚动条*/
::-webkit-scrollbar-thumb:horizontal {
  width: 5px;
  background-color: red;
  -webkit-border-radius: 6px;
}
</style>
