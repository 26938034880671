<!-- 常见问题 -->
<template>
  <div>
    <TitleBarBaseVue :handle="{ isSave: true }" @handleSave="handleSave" />
    <el-row>
      <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8" :offset="1">
        <PhoneMobileVue>
          <div class="main_wrap" v-html="question"></div>
        </PhoneMobileVue>
      </el-col>
      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <tinymce-editor ref="editor" v-model="question" :height="700" :disabled="disabled" :base-url="baseUrl">
        </tinymce-editor>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
import TinymceEditor from '@/components/RichText/tinymce-editor.vue'
import { saveSysQuestion, sysQuestion } from '../../../api/systemController'
import PhoneMobileVue from '../../../components/PhoneMobile/PhoneMobile.vue'

export default {
  data() {
    return {
      question: '',
      disabled: false,
      baseUrl: process.env.NODE_ENV === 'production' ? '/vue-use-tinymce' : ''
    }
  },
  methods: {
    async handleSave() {
      let [err, res] = await saveSysQuestion({ question: this.question })
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '保存失败')
      }
      console.log(res)
      this.$message.success(res.msg || '保存成功')
    },
    async getQuestion() {
      let [err, res] = await sysQuestion()
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取失败')
      }
      console.log(res)
      this.$message.success(res.msg || '获取成功')
      this.question = res.data.question
    }
  },
  mounted() {
    this.getQuestion()
  },
  components: {
    TitleBarBaseVue,
    TinymceEditor,
    PhoneMobileVue
  }
}
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
/deep/.main_wrap {
  padding: 10px;
  max-height: 641px;
  overflow: auto;
  img {
    width: 100% !important;
  }
}
</style>
