// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/wx/iphone_backImg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@them _red =\"#ea183a\";@them _bg =\"#3f9da8\";.phone_bg[data-v-79f37d55]{display:inline-block;width:419px;height:865px;padding:110px 29px 113px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% auto}[data-v-79f37d55]::-webkit-scrollbar{width:6px;height:6px}[data-v-79f37d55]::-webkit-scrollbar-track-piece{background-color:#fff4f4;-webkit-border-radius:6px}[data-v-79f37d55]::-webkit-scrollbar-thumb:vertical{height:5px;background-color:#ddd;-webkit-border-radius:6px}[data-v-79f37d55]::-webkit-scrollbar-thumb:horizontal{width:5px;background-color:red;-webkit-border-radius:6px}", ""]);
// Exports
module.exports = exports;
